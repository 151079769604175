import React, {FunctionComponent, useCallback} from 'react';
import {Button} from "react-bootstrap";
import {TbClipboardCopy} from "react-icons/tb";
import {toast} from "react-toastify";


interface Props {
    content: string;
}

const CopyToClipboardButton: FunctionComponent<Props> = ({content}) => {
    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(content).then(() => {
            toast("BibTeX entry copied to clipboard!");
        });
    }, [content]);

    return (
        <Button onClick={copyToClipboard} variant={"secondary"} className={"p-2"}>
            <TbClipboardCopy size={20}/>
        </Button>
    );
};

export default CopyToClipboardButton;
