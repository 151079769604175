import React, {FunctionComponent, PropsWithChildren} from 'react';
import styled from "styled-components";
import CopyToClipboardButton from "CopyToClipboardButton";
import {Form} from "react-bootstrap";

const StyledOuterContainer = styled.div`
    position: relative;
`;

const StyledButtonContainer = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1000;
`;

interface Props {
    labelText?: string;
    children: string;
}

const BibtexField: FunctionComponent<PropsWithChildren<Props>> = ({children, labelText}) => {
    return (
        <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                {labelText && <Form.Label>{labelText}</Form.Label>}
                <StyledOuterContainer>
                    <StyledButtonContainer>
                        <CopyToClipboardButton content={children}/>
                    </StyledButtonContainer>
                    <Form.Control style={{backgroundColor: "var(--bs-secondary-bg-subtle)", fontSize: "0.8em"}}
                                  disabled={true}
                                  as="textarea"
                                  className={"font-monospace"}
                                  rows={10} value={children}/>
                </StyledOuterContainer>
            </Form.Group>
        </Form>
    );
};

export default BibtexField;
