import React from 'react';
import './App.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import BibtexField from "BibtexField";
import {ToastContainer} from "react-toastify";

function App() {
    const citation = `@Article{Spinner2024GeneraitorTreeLoop,\n    author    = {Spinner, Thilo and Kehlbeck, Rebecca and Sevastjanova, Rita and Stähle, Tobias and Keim, Daniel A. and Deussen, Oliver and El-Assady, Mennatallah},\n    journal   = {ACM Transactions on Interactive Intelligent Systems},\n    title     = {generAItor: Tree-in-the-Loop Text Generation for Language Model Explainability and Adaptation},\n    year      = {2024},\n    doi       = {10.1145/3652028},\n    publisher = {Association for Computing Machinery (ACM)}\n}`;

    return (
        <div className="App">
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Container fluid={"xl"}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={8} style={{backgroundColor: "#fff"}} className={"pt-4 pb-5"}>
                        <h2 className="mb-4 fw-bold">
                            🌳-generAItor: Tree-in-the-Loop Text Generation for Language Model Explainability and
                            Adaptation
                        </h2>
                        <Container className="mt-2 mb-4">
                            <Row>
                                <Col md={4}>
                                    <h4>Authors</h4>
                                    <p>
                                        Thilo Spinner<sup>1</sup><br/>
                                        Rebecca Kehlbeck<sup>2</sup><br/>
                                        Rita Sevastjanova<sup>1</sup><br/>
                                        Tobias Stähle<sup>2</sup><br/>
                                        Daniel A. Keim<sup>2</sup><br/>
                                        Oliver Deussen<sup>2</sup><br/>
                                        Mennatallah El-Assady<sup>1</sup>
                                    </p>
                                </Col>
                                <Col md={4}>
                                    <h4>Published</h4>
                                    <p>
                                        2024
                                    </p>
                                </Col>
                                <Col md={4}>
                                    <h4>Journal</h4>
                                    <p>
                                        ACM Transactions on Interactive Intelligent Systems
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{fontSize: "0.8em"}}>
                                    <sup>1</sup>ETH Zurich, Switzerland&emsp;
                                    <sup>2</sup>University of Konstanz, Germany
                                </Col>
                            </Row>
                        </Container>
                        <p className="mb-4">
                            Large language models (LLMs) are widely deployed in various downstream tasks, e.g.,
                            auto-completion, aided writing, or chat-based text generation. However, the considered
                            output candidates of the underlying search algorithm are under-explored and under-explained.
                            We tackle this shortcoming by proposing a tree-in-the-loop approach, where a visual
                            representation of the beam search tree is the central component for analyzing, explaining,
                            and adapting the generated outputs. To support these tasks, we present generAItor, a visual
                            analytics technique, augmenting the central beam search tree with various task-specific
                            widgets, providing targeted visualizations and interaction possibilities. Our approach
                            allows interactions on multiple levels and offers an iterative pipeline that encompasses
                            generating, exploring, and comparing output candidates, as well as fine-tuning the model
                            based on adapted data. Our case study shows that our tool generates new insights in gender
                            bias analysis beyond state-of-the-art template-based methods. Additionally, we demonstrate
                            the applicability of our approach in a qualitative user study. Finally, we quantitatively
                            evaluate the adaptability of the model to few samples, as occurring in text-generation use
                            cases.
                        </p>
                        <Container className="mb-4">
                            <Row className="justify-content-md-center">
                                <Col md="auto" className={"mb-1"}>
                                    <Button href="https://doi.org/10.1145/3652028">Paper</Button>
                                </Col>
                                <Col md="auto" className={"mb-1"}>
                                    <Button href="https://demo.generaitor.ivia.ch">Demo System</Button>
                                </Col>
                                <Col md="auto" className={"mb-1"}>
                                    <Button href="https://main.generaitor.ivia.ch/">Full System</Button>
                                </Col>
                            </Row>
                        </Container>
                        <div className="ratio ratio-16x9 mb-4">
                            <iframe title={"generAItor Demo Video"} className="embed-responsive-item"
                                    src="https://www.youtube.com/embed/MdABGrlQ684"
                                    allowFullScreen></iframe>
                        </div>
                        <div>
                            <BibtexField labelText={"Please use the following BibTeX entry to cite this work:"}>
                                {citation}
                            </BibtexField>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default App;
